/* app.css */
:root {
  --primary-color: #280c82;
  --secondary-color: #f44067;
  --surface: #F4F2FF;
  --padding:1em;
  --primary-font: 'Roboto', sans-serif;
  --title-font: 'Poppins', sans-serif
}

.App {
  text-align: left;
  height:100dvh;
  height:100vh;  
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@400;700&display=swap');

body {
  font-family: var(--primary-font)
}


iframe {
  pointer-events: none;
}

h1, h2, h3 {
  font-family: var(--title-font); 
  margin: 0em;
}

/* Global styles for the entire app */
h1 {
  font-size: 2em;
  font-weight: regular; 
  line-height: 150%;
}

p {
  font-size: 1em;
  line-height: 100%;
  font-weight: regular; 
  margin-top: 0.5em; 
  margin-bottom: 0em;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: var(--secondary-color);
}

@font-face {
  font-family: 'Material Symbols';
  font-style: normal;
  font-weight: 400;
  src: local('Material Symbols'),
       local('Material-Symbols-Outlined'),
       url('/public/fonts/material-symbols/MaterialSymbolsOutlined.woff2') format('woff2'),
       url('/public/fonts/material-symbols/MaterialSymbolsOutlined.ttf') format('truetype');
}


.material-symbols-outlined {
  font-family: 'Material Symbols';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}